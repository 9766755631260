<div #overlay id="overlay">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
    style="shape-rendering: auto; display: block; background: rgb(255, 255, 255); margin: auto;"
    width="200"
    height="200"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <circle cx="50" cy="50" r="0" fill="none" stroke="#f7f7f7" stroke-width="2">
        <animate attributeName="r" repeatCount="indefinite" dur="1s" values="0;40" keyTimes="0;1"
          keySplines="0 0.2 0.8 1" calcMode="spline" begin="0s"></animate>
        <animate attributeName="opacity" repeatCount="indefinite" dur="1s" values="1;0" keyTimes="0;1"
          keySplines="0.2 0 0.8 1" calcMode="spline" begin="0s"></animate>
      </circle>
      <circle cx="50" cy="50" r="0" fill="none" stroke="#cccccc" stroke-width="2">
        <animate attributeName="r" repeatCount="indefinite" dur="1s" values="0;40" keyTimes="0;1"
          keySplines="0 0.2 0.8 1" calcMode="spline" begin="-0.5s"></animate>
        <animate attributeName="opacity" repeatCount="indefinite" dur="1s" values="1;0" keyTimes="0;1"
          keySplines="0.2 0 0.8 1" calcMode="spline" begin="-0.5s"></animate>
      </circle>
      <g></g>
    </g>
  </svg>
</div>
