<mat-tab-group color="primary" class="animate__animated animate__fadeIn" [color]="'primary'" [animationDuration]="'1000ms'" [dynamicHeight]="'true'"  mat-stretch-tabs="false" mat-align-tabs="center">
  <mat-tab *ngIf="secretElement" label="SOLID">
    <ng-template mat-tab-label>
      <span class="matTabFont">SOLID</span>
    </ng-template>
    <mat-tab-group>
      <mat-tab>
        <ng-template mat-tab-label>
          <span class="text-lg subpixel-antialiased" [matTooltip]="'Single Responsibility Principle'">🅂</span>
        </ng-template>
        <app-solid
          [title]="'S R P'"
          [description]="'Single Responsibility Principle'"
          [content]="srpText"
          [badCode]="codeBadSRP"
          [goodCode]="codeGoodSRP"
        />
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <span class="text-lg subpixel-antialiased" [matTooltip]="'Open Closed Principle'">🄾</span>
        </ng-template>
        <app-solid
          [title]="'O C P'"
          [description]="'Open Closed Principle'"
          [content]="ocpText"
          [badCode]="codeBadOCP"
          [goodCode]="codeGoodOCP"
        />
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <span class="text-lg subpixel-antialiased" [matTooltip]="'Liskov Substitution Principle'">🄻</span>
        </ng-template>
        <app-solid
          [title]="'L S P'"
          [description]="'Liskov Substitution Principle'"
          [content]="lspText"
          [badCode]="codeBadLSP"
          [goodCode]="codeGoodLSP"
        />
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <span class="text-lg subpixel-antialiased" [matTooltip]="'Interface Segregation Principle'">🄸</span>
        </ng-template>
        <app-solid
          [title]="'I S P'"
          [description]="'Interface Segregation Principle'"
          [content]="ispText"
          [badCode]="codeBadISP"
          [goodCode]="codeGoodISP"
        />
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <span class="text-lg subpixel-antialiased" [matTooltip]="'Dependency Inversion Principle'">🄳</span>
        </ng-template>
        <app-solid
          [title]="'D I P'"
          [description]="'Dependency Inversion Principle'"
          [content]="dipText"
          [badCode]="codeBadDIP"
          [goodCode]="codeGoodDIP"
        />
      </mat-tab>
    </mat-tab-group>
  </mat-tab>
  <mat-tab *ngIf="secretElement" label="12-fac-app">
    <ng-template mat-tab-label>
      <span class="matTabFont">12 Factor App</span>
    </ng-template>
    <img ngSrc="//gieldon.de/baustelle.gif" width="450" height="400" priority alt="under-construction">
  </mat-tab>
  <mat-tab *ngIf="secretElement" label="ddd">
    <ng-template mat-tab-label>
      <span class="matTabFont">Domain Driven Design</span>
    </ng-template>
    <img ngSrc="assets/img/ddd.png" width="400" height="200" priority alt="under-construction">
    <img ngSrc="assets/img/ddd-2.png" width="400" height="200" priority alt="under-construction">
  </mat-tab>
  <mat-tab label="otel">
    <ng-template mat-tab-label>
      <span class="matTabFont">OpenTelemetry</span>
    </ng-template>
    <embed [width]="'100%'" [height]="'555'" src="assets/pdf/expose.pdf" class="pdf-expose"/>
  </mat-tab>
</mat-tab-group>
