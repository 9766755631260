<ng-container *transloco="let t; prefix: 'main.home'">
  <cdk-virtual-scroll-viewport itemSize="50" class="virtual-scroll-viewport">
    <h2 [innerText]="t('welcome')" matTooltipPosition="above" [matTooltip]="(greetingTooltip$ | async) ?? ''"></h2>
    <hr>
    <section>
        <p [innerText]="t('p1')"></p>
        <p [innerText]="t('p2')"></p>
        <p [innerText]="t('p3')"></p>
        <mat-divider />
        <p [innerText]="t('p4')"></p>
    </section>
  </cdk-virtual-scroll-viewport>
</ng-container>
